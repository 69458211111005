import React from 'react';
import Img from "gatsby-image";
import {motion} from "framer-motion";
import {graphql, useStaticQuery} from "gatsby";
import {useIntl} from "react-intl";

const LatestWorks = ({value}) => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    const images = useStaticQuery(graphql`
    query {
      despensaApp: file(relativePath: { eq: "portfolio/despensaApp.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eBook: file(relativePath: { eq: "portfolio/ebook.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agendaCave: file(relativePath: { eq: "portfolio/agendaCave.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pokemon: file(relativePath: { eq: "portfolio/pokemon.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return (
        <motion.div
            className="item-panel-wrapper flex gap-4 md:gap-6 p-6 md:p-14"
            drag="x"
            dragConstraints={{left: -value, right: 0}}
            initial={{opacity: 0.8, x: 1000}}
            animate={{opacity: 1, x: 0, transition: {duration: 1.0}}}
        >
            <div className="item-panel-text flex justify-center flex-col gap-4">
                <h1 className="font-ExtraBold text-5xl xl:text-6xl">{f("latestWork")}</h1>
                <h2 className="font-Regular text-lg xl:text-xl">{f("latestWorkP2")}</h2>
                <a target="_blank" rel='noreferrer' href='https://duma.codes/' className='flex gap-1 justify-start items-center z-10'>
                    <span className='font-Bold text-lg md:text-sm xl:text-lg'>{f("seeMore")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#000000" width={22} height={22}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                    </svg>
                </a>
            </div>
            <div className="cursor-pointer flex flex-col justify-center items-center gap-2.5">
                <div className='item-panel bg-yellow rounded-10px flex items-center justify-center'>
                    <Img
                        style={{height: 480, width: 480}}
                        fluid={images.despensaApp.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                </div>
                <div className='flex justify-between items-center w-full'>
                    <p className='text-lg font-Regular'>Despensa App</p>
                    <p className='text-lg font-Regular opacity-40'>2020</p>
                </div>
            </div>
            <div className="cursor-pointer flex flex-col justify-center items-center gap-2.5">
                <div className='item-panel bg-purple rounded-10px flex items-center justify-center'>
                    <Img
                        style={{height: 480, width: 480}}
                        fluid={images.eBook.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                </div>
                <div className='flex justify-between items-center w-full'>
                    <p className='text-lg font-Regular'>E-Book</p>
                    <p className='text-lg font-Regular opacity-40'>2020</p>
                </div>
            </div>
            <div className="cursor-pointer flex flex-col justify-center items-center gap-2.5">
                <div className='item-panel bg-blue rounded-10px flex items-center justify-center'>
                    <Img
                        style={{height: 480, width: 480}}
                        fluid={images.agendaCave.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                </div>
                <div className='flex justify-between items-center w-full'>
                    <p className='text-lg font-Regular'>Agenda Cave</p>
                    <p className='text-lg font-Regular opacity-40'>2020</p>
                </div>
            </div>
            <div className="cursor-pointer flex flex-col justify-center items-center gap-2.5">
                <div className='item-panel bg-green rounded-10px flex items-center justify-center'>
                    <Img
                        style={{height: 480, width: 480}}
                        fluid={images.pokemon.childImageSharp.fluid}
                        imgStyle={{objectFit: "contain", objectPosition: 'center'}}
                    />
                </div>
                <div className='flex justify-between items-center w-full'>
                    <p className='text-lg font-Regular'>Technical Laboratory</p>
                    <p className='text-lg font-Regular opacity-40'>2020</p>
                </div>
            </div>
        </motion.div>
    );
};

export default LatestWorks;
