import * as React from "react";
import {useIntl} from "react-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Loader from "../components/loader";
import LatestWorks from "../components/latestWorksa";
import {useLocalization} from "gatsby-theme-i18n";

const PortfolioPage = (props) => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    const {locale} = useLocalization()

    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
        setHasMounted(true)
    }, [])
    if (!hasMounted) {
        return (<Loader/>)
    }

    return (
        <Layout location={props.location} color='#4353FF'>
            <SEO title={f("portfolio")} lang={locale} description={f("descriptionMeta")}/>
            <div className="overflow-hidden">
                <div className='block md:hidden'>
                    <LatestWorks value={2040}/>
                </div>
                <div className='hidden md:block xl:hidden'>
                    <LatestWorks value={1350}/>
                </div>
                <div className='hidden md:hidden xl:block'>
                    <LatestWorks value={1250}/>
                </div>
            </div>
        </Layout>
    );
};

export default PortfolioPage;
